import React, { useEffect, useState } from "react";
import { AgCharts } from "ag-charts-react";
import { Card, message } from "antd";
import { FetchStatusBaseOrder } from "../../../service/api_services";
import { useAuth } from "../../../authentication/context/authContext";



const TotalOrderStatus = () => {
    const { logout, token } = useAuth();
    const [orderStatus, setOrderStatus] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState({
        data: [],
        title: {
            text: "Service Booking All Status",

        },
        subtitle: {
            text: 'Booking Status ',
        },
        series: [
            {
                type: "pie",
                angleKey: "totalOrder",
                legendItemKey: "_id",
            },
        ],
    });

    const showOrderStatus = async () => {
        try {
            const res = await FetchStatusBaseOrder(token);
            console.log("order status", res);
            if (res.status === 200) {
                const data = res.data.data;
                setOrderStatus(data);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    data: data.map(item => ({
                        // quarter: item._id,
                        _id: item._id,
                        totalOrder: item.totalOrder,
                    })),
                }));
                setIsLoading(true);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        showOrderStatus();
    }, []);

    useEffect(() => {
        if (isLoading) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                data: orderStatus.map(item => ({
                    // quarter: item._id,
                    _id: item._id,
                    totalOrder: item.totalOrder,
                })),
            }));
        }
    }, [orderStatus, isLoading]);



    return (
        <Card>
            <AgCharts options={options} />
        </Card>

    )
};

export default TotalOrderStatus