import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Form, Input, Modal, Upload, message } from 'antd';
import "../../../style/product.css"
import { ResendOrderToVendor, UploadPincodeExcel } from '../../../service/api_services';
import { useAuth } from '../../../authentication/context/authContext';
import { UploadOutlined } from '@ant-design/icons';


const UploadBulkPincode = ({ orderId, ShowAllPincodeList }) => {
    const { token } = useAuth()
    const { form } = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(new FormData());





    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const submitHandler = async (value) => {
        try {
            formData.append("excelFile", value.excelFile.file);

            await UploadPincodeExcel(token, formData)
                .then((res) => {
                    console.log(" upload excel", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        setIsModalOpen(false);
                        ShowAllPincodeList()
                        setFormData(new FormData())
                        form.resetFields();

                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    setFormData(new FormData())

                });
        } catch (error) {
            console.log(error);
        }
    }

    const onFinishFailed = (value) => {
        console.log(value)
    }


    return (
        <div className='upload_pincode_models'>
            <div className="import_file">
                <Button onClick={showModal} shape='round'>Import File</Button>
            </div>

            <Modal width={400} title="Upload Pincode Excel file" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <div className="excelfile_form">

                    <Form layout="vertical" form={form} onFinish={submitHandler} onFinishFailed={onFinishFailed}>
                        <Form.Item label="Select  File" name="excelFile">
                            <Upload
                                multiple={false}
                                listType="file"
                                beforeUpload={() => false} // To prevent automatic upload
                                accept="xlsx"
                                onChange={(file) => console.log(file)}
                            >
                                <Button type='primary' icon={<UploadOutlined className='mx-2'/>}> Select Excel File</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <div className="assign_pin_btn">
                                <Button htmlType='submit' shape='round'>Submit</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};


export default UploadBulkPincode