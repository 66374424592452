import { Avatar, Descriptions, Tag } from 'antd'
import React from 'react'

function EmergencyDetails({ vendorInfo }) {
    return (
        <div className='personal_details'>
            <Descriptions bordered layout="vertical">

                <Descriptions.Item label="Guardian Name">
                    {vendorInfo?.gurdianName!=null ? vendorInfo?.gurdianName :  <span>N/A</span>}
                </Descriptions.Item>
                <Descriptions.Item label="Guardian Number">
                    {vendorInfo?.gurdianPhoneNumber!=null ? vendorInfo?.gurdianPhoneNumber :  <span>N/A</span>}
                </Descriptions.Item>

                <Descriptions.Item label="RelationShip">
                    {vendorInfo?.relationShip!=null ? vendorInfo?.relationShip :  <span>N/A</span>}
                </Descriptions.Item>

            </Descriptions>
        </div>
    )
}


export default EmergencyDetails