import { Descriptions, Empty, Image } from 'antd'
import React from 'react'
import EmptyImg from "../../assest/png/NotImg.png"

function VendorDocument({ vendorInfo }) {
    return (
        <div className='vendor_doc'>
            <Descriptions bordered layout="vertical">

                <Descriptions.Item label="PanCard Image">
                    {vendorInfo?.panCardFile != null ? <Image src={vendorInfo?.panCardFile} width={120} style={{ borderRadius: "10px" }} /> : <Image src={EmptyImg} width={120} style={{ borderRadius: "10px" }} />}
                </Descriptions.Item>
                {vendorInfo?.aadharCardFile?.length == 0 ?
                    <>
                        <Descriptions.Item label="AadharCard Image">
                            <Empty />
                        </Descriptions.Item> <Descriptions.Item label="AadharCard Image">
                            <Empty />
                        </Descriptions.Item>
                    </> : vendorInfo?.aadharCardFile.map((url, index) => <Descriptions.Item label={`AadharCard Image - ${index + 1}`}>
                        <Image src={url} width={120} style={{ borderRadius: "10px" }} />
                    </Descriptions.Item>)}


               
            </Descriptions>
        </div>
    )
}

export default VendorDocument