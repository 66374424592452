import { Breadcrumb, Button, Form, Image, Input, Spin, Typography } from "antd";
import React from "react";

import { Space, Table, Tag } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import AdminRoleProvide from "./AdminRoleProvide";
import CreateAdmin from "./CreateAdmin";
import { useAuth } from "../context/authContext";
import { useLocation } from "react-router-dom";
import "../../style/master.css"
// import { adminUser } from "../../service/DummyData";


const columns = [
  {
    title: "Image",
    dataIndex: "url",
    key: "url",
    render: (_, { url }) => (
      <Image  src={url} width={45} height={45}  style={{borderRadius:"100%" , objectFit:"cover"}}/>
    ),
  },
  {
    title: "Number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "Created Date",
    dataIndex: "join_date",
    key: "join_date",

  },

  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render: (_, { role }) => (
      <Tag color="blue">{role}</Tag>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_, { status }) => (
      <>{status == "Active" ? <Tag color="green">{status}</Tag> : <Tag color="red">{status}</Tag>}</>
    ),
  },


];

function RoleManagement() {
  const { token } = useAuth()
  const [TypeList, settypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { Title } = Typography;

  // const ShowCarType = async () => {
  //     try {
  //       await CarTypes(token)
  //         .then((res) => {
  //           console.log("car typr list", res);
  //           if (res.status == 200) {
  //             settypeList(res.data.data);
  //             setIsLoading(true);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err.response.msg);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //       setIsLoading(true);
  //     }
  //   };

  return (
    <section className="container">
      <Breadcrumb
        items={[
          {
            title: "Dashboard",
          },
          {
            title: location.pathname,
          },
        ]}
      />
      <div className="content_title">
        <div className="content_head">
          <div className="content_title">
            <Title level={4}>Role Manegement</Title>
          </div>
          <div className="content_add">

            <CreateAdmin />


          </div>
        </div>
        <div className="content">
          <div className="shoo_recent_order">
            <Table columns={columns} dataSource={[]} scroll={{ x: true }} />
          </div>
        </div>


      </div>

    </section>
  );
}



export default RoleManagement