import React, { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import "../../style/vendor.css"
import { InsertNewBanner, InsertNewCategory } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import { BannerType } from "../../service/api_services";
import { CategoryHook } from "../../pages/CustomHooks";
// import { useAuth } from "../../authentication/context/authContext";
// import { InsertNewCategory } from "../../service/api_services";


function AddBanner({ ShowAllBannerList }) {
  const { token } = useAuth()
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const [form] = Form.useForm();
  const [uploder, setUploader] = useState(null)
  const [bannerTypes, setBannerTypes] = useState("")
  const { defaultId, categoryList, setDefaultId } = CategoryHook(token)




  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (value) => {
    console.log(value)
    try {
      formData.append("bannerImage", value.bannerImage.file);
      formData.append("bannerType", value.bannerType);
      bannerTypes != "DASHBOARD" && formData.append("categoryId", value.categoryId);
      formData.append("description", value.description);
      formData.append("title", value.title);


      await InsertNewBanner(token, formData)
        .then((res) => {
          console.log(" add new category", res);
          if (res.status == 201) {
            message.success(res.data.message);
            setOpen(false);
            onReset();
            setFormData(new FormData());
            ShowAllBannerList()
            setUploader(null)
          } else if (res.status == 200) {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setFormData(new FormData());

        });
    } catch (error) {
      console.log(error);
      setFormData(new FormData());

    }
  };

  const onReset = () => {
    form.resetFields();
  };
  const handlerImage = (file) => {
    console.log(file)
    setUploader(file.fileList)
  }


  return (
    <div className="modal_section">

      <Button
        type="primary"
        className="primary_btn"
        shape="round"
        onClick={showDrawer}
      >
        Add
      </Button>

      <Drawer
        title="Add New Banner"
        placement="right"
        width={400}

        onClose={onClose}
        open={open}
      >
        <div className="add_category_form">
          <Form
            form={form}
            layout="vertical"
            name="add-image"
            className="images"
            initialValues={{
              title: "",
              description: "",
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Col md={24}>
              <Form.Item
                name="bannerType"
              >
                <Select
                  showSearch
                  placeholder="Select Banner Type "
                  optionFilterProp="children"
                  onChange={(value) => setBannerTypes(value)}

                >
                  {BannerType?.map((option) => (
                    <Select.Option
                      key={option.key}

                      value={option.values}
                    >
                      {option.values}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                name="categoryId"
              >
                <Select disabled={bannerTypes == "DASHBOARD"}
                  showSearch
                  placeholder="Select Category "
                  optionFilterProp="children"
                  initialValues=""

                >
                  {categoryList?.map((option) => (
                    <Select.Option

                      key={option.categoryName}
                      level={option.categoryName}
                      value={option._id}
                    >
                      {option.categoryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={24}>
              <Form.Item
                label="Title"
                name="title"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Enter title",
              //   },
              // ]}
              >

                <Input initialValues="" placeholder="Enter title" disabled={bannerTypes != "DASHBOARD"} />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Description"
                name="description"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Enter description",
              //   },
              // ]}
              >
                <Input.TextArea maxLength={250} initialValues="" placeholder="Enter description" disabled={bannerTypes != "DASHBOARD"} />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please Choose any Image ",
                  },
                ]}
                name="bannerImage" label={uploder?.length != 1 ? "Choose  Image" : "If you want to choose other image, remove exit Image"}>
                <Upload
                  multiple={false}
                  listType="picture-circle"
                  beforeUpload={() => false} // To prevent automatic upload
                  accept="jpg/jpeg/pdf"
                  onChange={handlerImage}
                >
                  {uploder?.length != 1 ? <img src="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg" className="image_hold" /> : null}
                </Upload>
              </Form.Item>
            </Col>
            <div className="model_Submit">
              <Button onClick={onClose} shape="round" danger>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
}

export default AddBanner;