import { Descriptions, Empty, Image } from 'antd'
import React from 'react'
import DefaultImage from "../../assest/icon/imageIcon.png"

function WorkedImage({ vendorInfo }) {
    return (
        <div className='vendor_doc'>
            <Descriptions bordered layout="vertical">


                {vendorInfo?.workedImage.map((url, index) => <Descriptions.Item label={`Worked Image - ${index + 1}`}>
                    <Image src={url!=null ? url : DefaultImage} width={120} style={{ borderRadius: "10px" }} />
                </Descriptions.Item>)}



            </Descriptions>
        </div>
    )
}

export default WorkedImage