import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Checkbox, Descriptions, Image, List, Segmented, Select, Space, Statistic, Table, Tabs, Tag, Typography } from "antd";
import '../../style/vendor.css'
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";


import { FiArrowUpRight, FiArrowDownLeft } from "react-icons/fi";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,

    SyncOutlined,
} from '@ant-design/icons';
import { FetchVendorWallet, FetchVendorWalletDedect } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";

function WalletDedectTransation() {
    const navigate = useNavigate()
    const { Title } = Typography
    const location = useLocation();
    const { id } = useParams()
    const { token } = useAuth()
    const [transationList, setTransationList] = useState([])
    const [isLoading, setIsLoading] = useState(false)



    const columns = [
        {
            title:
                "Transation ID"
            ,
            dataIndex: "id",
            key: "id",
            render: (_, { id, type }) => (
                <Space>
                     <Button id="withdraw_btn" shape="circle" icon={<FiArrowUpRight className="transIcon" />} > </Button> 
                    <p>{id}</p>
                </Space>
            )
        },
        {
            title:
                "Date"
            ,
            dataIndex: "date",
            key: "date",
        },
        {
            title:
                "Type"
            ,
            dataIndex: "type",
            key: "type",
            render: (_, { type }) => (
                <div className="transId">
                    {type == "Withdraw" ? <Tag color="red" bordered={false} >{type} </Tag> :
                        <Tag color="green" bordered={false} >{type} </Tag>
                    }

                </div>
            )
        },
        {
            title:
                "Amount"
            ,
            dataIndex: "amount",
            key: "amount",
            render: (_, { amount, type }) => (
                <>
                    {type == "Withdraw" ? <p style={{ color: "crimson" }}>- {amount}</p> : <p style={{ color: "green" }}>+ {amount}</p>}
                </>
            )
        },
        {
            title:
                "Pay Option"
            ,
            dataIndex: "pay_type",
            key: "pay_type",

        },

        {
            title:
                "Status"
            ,
            dataIndex: "status",
            key: "status",
            render: (_, { status }) => (
                <div>
                    {status == "failed" ? <Tag icon={<CloseCircleOutlined />} color="red" >{status}</Tag> : status == "success" ? <Tag icon={<CheckCircleOutlined />} color="green">{status}</Tag> : <Tag color="blue" icon={<SyncOutlined spin />}>{status}</Tag>}
                </div>
            )
        },

    ];

    const ShowVendorWalletTransList = async () => {
        try {
            await FetchVendorWalletDedect(token, id)
                .then((res) => {
                    console.log(" wallet dedect list ", res);
                    if (res.status == 200) {
                        setTransationList(res.data.data.data);
                        setIsLoading(true)
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowVendorWalletTransList()
    }, [])




    return (
        <section className="main_Section">
           
            <div className="content_title">

                <div className="content">
                    <div className="vendor_transation_data">
                        <Table columns={columns} dataSource={transationList} scroll={{ x: true }} />
                    </div>
                </div>


            </div>

        </section>
    );
}





export default WalletDedectTransation