import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Image, Pagination, Popconfirm, Statistic, Typography, message } from "antd";
import '../../../style/location.css'
import { useLocation } from "react-router-dom";
import { Space, Table, Tag } from 'antd';
import { MdOutlineDelete } from "react-icons/md";
import AddPincode from "./AddPincode";
import { FetchPincodeList, deletePincodeAction } from "../../../service/api_services";
import { useAuth } from "../../../authentication/context/authContext";
import UploadBulkPincode from "./UploadBulkPincode";
import EditPincode from "./EditPincode";


function PincodeList() {
    const location = useLocation();
    const { token, logout } = useAuth()
    const [pinList, setPinList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [current, setCurrent] = useState(1)
    const [totalPage, setTotalPage] = useState(null)

    const onChange = (page) => {
        setCurrent(page);
        setIsLoading(true);
    };


    const { Title } = Typography;
    const columns = [
        {
            title: 'Pincode',
            dataIndex: 'pincode',
            key: 'pincode',

        },
        {
            title: 'Place',
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: 'Created',
            dataIndex: 'addedDate',
            key: 'addedDate',
            render: (_, { addedDate }) => (
                <>
                    {new Date(addedDate).toLocaleDateString()}
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (_, { status }) => (
                <>
                    <Tag color={`${status == "ACTIVE" ? "green" : "red"}`} >
                        {status}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Action',
            key: '_id',
            render: (_, { _id }) => (
                <div className="vendor_action">
                    {/* <EditPincode /> */}
                    <Popconfirm
                        title="Delete the Pincode"
                        description="Are you sure to delete this Pincode?"
                        onConfirm={() => deleteCategory(_id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <MdOutlineDelete className="delete_icon" />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const cancel = () => {
        message.error("you are select no")
    }


    const ShowAllPincodeList = async () => {
        try {
            await FetchPincodeList(token, current)
                .then((res) => {
                    console.log(" pincode  list ", res);
                    if (res.status == 200) {
                        setPinList(res.data.data.data);
                        setTotalPage(res.data.data.totalPage)
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowAllPincodeList()
    }, [current])


    //delete pincode 


    const deleteCategory = async (id) => {
        try {
            let body = {
                pincodeId: id,
            };
            await deletePincodeAction(body, token)
                .then((res) => {
                    console.log("delete pin ", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        ShowAllPincodeList();
                    }
                })
                .catch((err) => {
                    console.log(err.response.msg);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true);
        }
    };

    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_title">
                        <Title level={4}>Pincode List</Title>
                    </div>
                    <div className="content_add">
                        <Space>
                            <UploadBulkPincode ShowAllPincodeList={ShowAllPincodeList} />
                            <AddPincode ShowAllPincodeList={ShowAllPincodeList} />
                        </Space>
                    </div>
                </div>
                <div className="content">
                    <div className="shoo_recent_order">
                        {!isLoading ? <div className="loader_main"> <span class="loader"></span></div> : <Table columns={columns} dataSource={pinList} scroll={{ x: true }}
                            pagination={false}
                            footer={() => <div className="pagination">
                                <Pagination current={current} onChange={onChange} total={totalPage * 10} />
                            </div>}

                        />}
                    </div>
                </div>
            </div>

        </section>
    );
}




export default PincodeList