import React, { useEffect, useState } from "react";

import {
  Button,

  Form,
  Input,
  Select,
  Breadcrumb,
  message,
  Typography,
  Card,
  Divider,

} from "antd";
import "../../style/service.css";
import { useAuth } from "../../authentication/context/authContext";
import ContactImage from "../../assest/png/12983800_5124105(1).jpg"
import { useLocation } from "react-router-dom";
import { TimePicker } from 'antd';
import { Col, Row } from "react-bootstrap";
import { FetchContactDetails, FetchGeneralSetting, InsertNewSetting } from "../../service/api_services";
import AddContactDetails from "./AddContactDetails";
const { Title } = Typography;

function GenetalSetting() {
  const { token, logout } = useAuth()
  const location = useLocation();
  const [form] = Form.useForm();
  const [settingData, setSettingData] = useState([])
  const [contactData, setContactData] = useState([])

  const option = {
    timeZone: 'UTC', // Set the time zone to UTC to prevent conversion

  };



  const ShowGeneralSetting = async () => {
    try {
      await FetchGeneralSetting(token)
        .then((res) => {
          console.log(" show setting", res);
          if (res.status == 200) {
            setSettingData(res.data.data);

            form.setFieldsValue({
              gst: res.data.data.gst,
              platformFee: res.data.data.platformFee,
              vendorMinWallet: res.data.data.vendorMinWallet,
              cancelAmountDeduct: res.data.data.cancelAmountDeduct,


            });
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    ShowGeneralSetting()
  }, [])

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (value) => {

    try {
      await InsertNewSetting(token, value)
        .then((res) => {
          console.log("add  setting", res);
          if (res.status == 201) {
            message.success(res.data.message);
            ShowGeneralSetting()
            onReset();
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const validateMaxLength = (_, value) => {
    if (value && value > 100) {
      return Promise.reject(new Error('Please Enter Valid % value less then 100 !'));
    }
    return Promise.resolve();
  };

  //contact data




  const ShowContactData = async () => {
    try {
      await FetchContactDetails(token)
        .then((res) => {
          console.log(" show contact", res);
          if (res.status == 200) {
            setContactData(res.data.data);
          } else if (res.data.code == 283) {
            message.error(res.data.message)
            logout()
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    ShowContactData()
  }, [])




  return (
    <section className="main_Section">
      <Breadcrumb
        items={[
          {
            title: "Dashboard",
          },
          {
            title: location.pathname,
          },
        ]}
      />
      <div className="content_title">
        <div className="content_head">
          <Title level={3}>General Setting</Title>
        </div>

      </div>
      <div className="content_main">

        <div className="show_setting">
          <Row>
            <Col md={8}>
              <Card size="small" title={<div>
                <p>Last Modify Setting : {new Date(settingData?.modifiedDate).toLocaleString('en-IN', option)}</p>

              </div>}


              >
                <Form
                  form={form}
                  layout="vertical"
                  name="general-getting"
                  className="settings"

                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Col md={12}>
                    <Form.Item
                      label="Enter Platform Fee in %"
                      name="platformFee"
                      rules={[
                        {
                          required: true,
                          message: "Plese Enter Platform Fee in %",
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: 'Please Enter only Numbers!',
                        },
                        { validator: validateMaxLength }
                      ]}
                    >
                      <Input placeholder="Enter Platform Fee in %" addonAfter="%" />
                    </Form.Item>
                  </Col>

                  <Col md={12}>
                    <Form.Item
                      label="GST %"
                      name="gst"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter GST",
                        },
                      ]}
                    >
                      <Input placeholder="Enter GST" addonAfter="%" />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      label="Vendor min. Wallet Amount"
                      name="vendorMinWallet"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Vendor CheckIn Time",
                        },
                      ]}
                    >
                      <Input placeholder="vendor Min Wallet" addonAfter="₹" />

                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      label="Cancel Amount Deduct"
                      name="cancelAmountDeduct"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Aancel Amount Deduct",
                        },
                      ]}
                    >
                      <Input placeholder="Cancel Amount Deduct" addonAfter="₹" />

                    </Form.Item>
                  </Col>
                  <div className="model_Submit">

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="primary_btn"
                      shape="round"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
            <Col md={4}>
              <Card title={<div className="contact_title">
                <p>Contact Information</p>
                <AddContactDetails />

              </div>}
                hoverable

                cover={<img alt="example" src={ContactImage} id="contact_image" />}
              >
                <Card.Meta className="mb-2" avatar={<img className="contactIcon" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmDLrBA4iERH2pkzZF46F7foLCLzZh7IbgYQ&s" />} title={

                  <p>{contactData?.mobileNo}</p>

                } />

                <Card.Meta avatar={<img className="contactIcon" src="https://i.pinimg.com/originals/c7/6b/1b/c76b1b21a3cac4ab1c5de4c11938429c.png" />} title={

                  <p>{contactData?.email}</p>

                } />



              </Card>
            </Col>
          </Row>
        </div>



      </div>
    </section>
  );
}



export default GenetalSetting