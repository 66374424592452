import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Breadcrumb, Button, Card, Form, Image, Input, Pagination, Popconfirm, Statistic, Typography, message } from "antd";
import '../../../style/location.css'
import { useLocation } from "react-router-dom";
import { Space, Table, Tag } from 'antd';
import AddCategory from "./AddCategory";
import { HiOutlineEye } from "react-icons/hi";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { FetchCategoryList, deleteCategoryFromList } from "../../../service/api_services";
import { useAuth } from "../../../authentication/context/authContext";
import { Icons } from "../../../common/icons";
import { SearchOutlined } from '@ant-design/icons';
import EditCategory from "./EditCategory";
import _debounce from 'lodash/debounce';







function Cateogry() {
    const location = useLocation();
    const { token, logout } = useAuth()
    const [totalPage, setTotalPage] = useState(null)
    const [categoryList, setCategoryList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [current, setCurrent] = useState(1)

    const { Title } = Typography;
    const columns = [
        {
            title: 'Image',
            dataIndex: 'categoryImage',
            key: 'categoryImage',
            render: (_, { categoryImage }) => (
                <div className="show_cat_img" >
                    {categoryImage != null ?
                        <Image src={categoryImage} width={40} height={40} style={{ objectFit: "cover", borderRadius: "100%" }} /> : <Avatar
                            size={40}
                            style={{
                                backgroundColor: '#f8229f',
                                cursor: "pointer"
                            }}
                            icon={<span >{Icons.User}</span>}
                        />}


                </div>
            )
        },
        {
            title: 'Name',
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: 'Created',
            dataIndex: 'addedDate',
            key: 'addedDate',
            render: (_, { addedDate }) => (
                <>{new Date(addedDate).toLocaleDateString()}</>
            )
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (_, { status }) => (
                <>
                    <Tag color={`${status == "ACTIVE" ? "green" : "red"}`} >
                        {status}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Action',
            key: 'id',
            render: (_, { _id, categoryImage, categoryName }) => (
                <div className="vendor_action">
                    <EditCategory  ShowAllCategoryList={ShowAllCategoryList} editData={{
                        categoryId: _id,
                        categoryImage: categoryImage,
                        categoryName: categoryName,
                    }} />

                    <Popconfirm
                        title="Delete the Service"
                        description="Are you sure to delete this Servcie?"
                        onConfirm={() => deleteCategory(_id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <MdOutlineDelete className="delete_icon" />
                    </Popconfirm>

                </div>
            ),
        },
    ];
    const cancel = () => {
        message.error("you are enter no")
    }
    const ShowAllCategoryList = async () => {
        try {
            await FetchCategoryList(token, current, searchInput)
                .then((res) => {
                    console.log(" all category list ", res);
                    if (res.status == 200) {
                        setCategoryList(res.data.data.data);
                        setTotalPage(res.data.data.totalPage)
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowAllCategoryList()
    }, [current, searchInput])

    const deleteCategory = async (id) => {
        try {
            let body = {
                categoryId: id,
            };
            await deleteCategoryFromList(body, token)
                .then((res) => {
                    console.log("delete category ", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        ShowAllCategoryList();
                    }
                })
                .catch((err) => {
                    console.log(err.response.msg);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true);
        }
    };

    const onChange = (page) => {
        setCurrent(page);
        setIsLoading(true);
    };

    const handleSearchChange = useCallback(_debounce((value) => {
        setSearchInput(value);
    }, 1000), []);

    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_title">
                        <Title level={4}> Category List</Title>
                    </div>
                    <div className="content_add">
                        <Space>
                            <Form.Item name="category">
                                <Input
                                    style={{ width: '200px' }}
                                    allowClear
                                    placeholder="Search Categoty"
                                    suffix={<SearchOutlined />}
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <AddCategory ShowAllCategoryList={ShowAllCategoryList} />
                            </Form.Item>
                        </Space>
                    </div>
                </div>
                <div className="content">
                    <div className="shoo_recent_order">
                        {!isLoading ? <div className="loader_main"> <span class="loader"></span></div> : <Table columns={columns} dataSource={categoryList} scroll={{ x: true }}
                            pagination={false}
                            footer={() => <div className="pagination">
                                <Pagination current={current} onChange={onChange} total={totalPage * 10} />
                            </div>}
                        />}
                    </div>
                </div>


            </div>

        </section>
    );
}



export default Cateogry