import axios from "axios";
// export const baseURL = "http://43.205.136.79:2200/api/admin";
// export const baseURL = "https://node.cutenails.in/api/admin";
// export const baseURL = "http://192.168.1.106:2200/api/admin";

export const baseURL = process.env.REACT_APP_BASE_URL
export const OrderStatus = {
  pending: "PENDING",
  upcoming: "UPCOMING",
  ongoing: "ONGOING",
  completed: "COMPLETED",
  cancelled: "CANCELLED",
  notPicked: "NOTPICKED",
};

export const ApprovalStatus = [
  {
    key: 1,
    value: "PENDING",
    label: "Pending",
  },
  {
    key: 2,
    value: "APPROVED",
    label: "Approved",
  },
  {
    key: 3,
    value: "REJECTED",
    label: "Rejected",
  },
];
export const documentStatus = [
  {
    key: 1,
    value: "UPLOADED",
    label: "Uploaded",
  },
  {
    key: 2,
    value: "NOTUPLOADED",
    label: "Not Uploaded",
  },
];
export const getorderStatus = [
  {
    key: 1,
    values: "PENDING",
    label: "Pending",
  },
  {
    key: 1,
    values: "UPCOMING",
    label: "Upcoming",
  },
  {
    key: 1,
    values: "ONGOING",
    label: "Ongoing",
  },
  {
    key: 1,
    values: "COMPLETED",
    label: "Completed",
  },
  {
    key: 1,
    values: "CANCELLED",
    label: "Cancelled",
  },
  {
    key: 1,
    values: "NOTPICKED",
    label: "NotPicked",
  },
];

export const timeDuration = [
  {
    key: 1,
    values: "HOURS",
  },
  {
    key: 2,
    values: "MINUTS",
  },
];
export const BannerType = [
  {
    key: 1,
    values: "DASHBOARD",
    label: "Dashboard",
  },
  {
    key: 2,
    values: "CATEGORY",
    label: "Category",
  },
];
export const DiscountTypes = [
  {
    key: 1,
    values: "PERCENTAGE",
  },
  // {
  //   key: 2,
  //   values: "FLAT",
  // },
];

//all urls

const limit = 10;

export const Services = {
  loginUrl: `${baseURL}/login`,
  categoryListUrl: `${baseURL}/category-list`,
  addCategoryUrl: `${baseURL}/category`,
  deleteServiceUrl: `${baseURL}/service`,
  deleteCategoryUrl: `${baseURL}/category`,
  addNewService: `${baseURL}/category/service`,
  fetchVendorUrl: `${baseURL}/vendor-list`,
  vendorRejectUrl: `${baseURL}/vendor/action-documents`,
  vendorAcceptUrl: `${baseURL}/vendor/action-documents`,
  fetchPincodeUrl: `${baseURL}/pincode`,
  addPincodeUrl: `${baseURL}/pincode`,
  deletePincodeUrl: `${baseURL}/pincode`,
  addbannerUrl: `${baseURL}/banner`,
  fetchBannerUrl: `${baseURL}/banner-list`,
  deleteBannerUrl: `${baseURL}/banner`,
  fetchDiscountUrl: `${baseURL}/discount-list`,
  AddDiscountUrl: `${baseURL}/discount`,
  deleteDiscountUrl: `${baseURL}/discount`,
  fetchFaqUrl: `${baseURL}/faq`,
  deleteFaqUrl: `${baseURL}/faq`,
  AddFaqUrl: `${baseURL}/faq`,
  fetchSettingUrl: `${baseURL}/general-setting`,
  AddSettingUrl: `${baseURL}/general-setting`,
  AddSettingUrl: `${baseURL}/general-setting`,
  getContactUrl: `${baseURL}/contact-us`,
  addContactUrl: `${baseURL}/contact-us`,
  resendOrderUrl: `${baseURL}/resend-order`,
  pincodeExcelUrl: `${baseURL}/pincode-excel-upload`,
  PincodeTotalOrderUrl: `${baseURL}/pincode-based-total-order`,
  TotalOrderStatusUrl: `${baseURL}/status-wise-order`,
  AllCountAnaliticsUrl: `${baseURL}/total-analytics-data`,
  fetchAdminProfileUrl: `${baseURL}/profile`,
  updateAdminProfileUrl: `${baseURL}/profile`,
  deleteVendorUrl: `${baseURL}/vendor`,
  editServiceUrl: `${baseURL}/update-service`,
  editCategoryUrl: `${baseURL}/update-category`,
};

//login section
export const LoginAdmin = async (body) => {
  const log = await axios.post(Services.loginUrl, body);
  return log;
};

//ctegory

export const FetchCategoryList = async (token, current, searchInput) => {
  const cat = await axios.get(
    `${baseURL}/category-list?searchData=${searchInput}&currentPage=${current}&itemsPerPage=${limit}`,
    {
      headers: { Authorization: token },
    }
  );
  return cat;
};

export const InsertNewCategory = async (token, body) => {
  const cat = await axios.post(Services.addCategoryUrl, body, {
    headers: { Authorization: token },
  });
  return cat;
};

export const UpdateCategory = async (token, body) => {
  const cat = await axios.put(Services.editCategoryUrl, body, {
    headers: { Authorization: token },
  });
  return cat;
};
export const UpdateService = async (token, body) => {
  const cat = await axios.put(Services.editServiceUrl, body, {
    headers: { Authorization: token },
  });
  return cat;
};

export const FetchServiceList = async (
  token,
  categoryId,
  current,
  searchInput
) => {
  const service = await axios.get(
    `${baseURL}/category/${categoryId}/service-list?searchData=${searchInput}&currentPage=${current}&itemsPerPage=${limit}`,
    {
      headers: { Authorization: token },
    }
  );
  return service;
};

export const FetchParticularService = async (token, serviceId) => {
  const service = await axios.get(`${baseURL}/service/${serviceId}`, {
    headers: { Authorization: token },
  });
  return service;
};

export const deleteServiceFromList = async (body, token) => {
  const del = await axios.delete(Services.deleteServiceUrl, {
    data: body,
    headers: { Authorization: token },
  });
  return del;
};

export const deleteCategoryFromList = async (body, token) => {
  const del = await axios.delete(Services.deleteCategoryUrl, {
    data: body,
    headers: { Authorization: token },
  });
  return del;
};

export const InsertNewService = async (token, body) => {
  const addser = await axios.post(Services.addNewService, body, {
    headers: { Authorization: token },
  });
  return addser;
};

//vendor

export const FetchVendorList = async (
  token,
  current,
  docApproval,
  docUpload,
  searchInput
) => {
  const vendor = await axios.get(
    `${baseURL}/vendor-list?currentPage=${current}&itemsPerPage=${limit}&documentVerifiedFilter=${
      docApproval != null ? docApproval : ""
    }&uploadFilter=${
      docUpload != null ? docUpload : ""
    }&searchData=${searchInput}`,
    {
      headers: { Authorization: token },
    }
  );
  return vendor;
};
export const FetchVendorDetails = async (token, vendorId) => {
  const vendorDetails = await axios.get(`${baseURL}/vendor/${vendorId}`, {
    headers: { Authorization: token },
  });
  return vendorDetails;
};

export const VendorApproval = async (token, body) => {
  const accept = await axios.post(Services.vendorAcceptUrl, body, {
    headers: { Authorization: token },
  });
  return accept;
};
export const VendorRejected = async (token, body) => {
  const accept = await axios.post(Services.vendorRejectUrl, body, {
    headers: { Authorization: token },
  });
  return accept;
};

export const FetchPincodeList = async (token, current) => {
  const pin = await axios.get(
    `${baseURL}/pincode?currentPage=${current}&itemsPerPage=${limit}`,
    {
      headers: { Authorization: token },
    }
  );
  return pin;
};

export const AddNewPincode = async (token, body) => {
  const addPin = await axios.post(Services.addPincodeUrl, body, {
    headers: { Authorization: token },
  });
  return addPin;
};

export const deletePincodeAction = async (body, token) => {
  const delPin = await axios.delete(Services.deletePincodeUrl, {
    data: body,
    headers: { Authorization: token },
  });
  return delPin;
};

export const InsertNewBanner = async (token, body) => {
  const banner = await axios.post(Services.addbannerUrl, body, {
    headers: { Authorization: token },
  });
  return banner;
};

export const FetchBannerList = async (token, banner) => {
  const banners = await axios.get(
    `${baseURL}/banner-list?filterBannerType=${banner != "" ? banner : ""}`,
    {
      headers: { Authorization: token },
    }
  );
  return banners;
};

export const deleteExistBanner = async (body, token) => {
  const del = await axios.delete(Services.deleteBannerUrl, {
    data: body,
    headers: { Authorization: token },
  });
  return del;
};

export const FetchDiscountList = async (token, current) => {
  const discount = await axios.get(
    `${baseURL}/discount-list?currentDate=${new Date()}&currentPage=${current}&itemsPerPage=${limit}`,
    {
      headers: { Authorization: token },
    }
  );
  return discount;
};

export const deleteDiscount = async (body, token) => {
  const del = await axios.delete(Services.deleteDiscountUrl, {
    data: body,
    headers: { Authorization: token },
  });
  return del;
};

export const InsertNewDiscount = async (body, token) => {
  const discount = await axios.post(Services.AddDiscountUrl, body, {
    headers: { Authorization: token },
  });
  return discount;
};

export const FetchFAQList = async (token) => {
  const faq = await axios.get(Services.fetchFaqUrl, {
    headers: { Authorization: token },
  });
  return faq;
};

export const deleteFAQ = async (body, token) => {
  const del = await axios.delete(Services.deleteFaqUrl, {
    data: body,
    headers: { Authorization: token },
  });
  return del;
};

export const InsertNewFAQ = async (token, body) => {
  const addFaq = await axios.post(Services.AddFaqUrl, body, {
    headers: { Authorization: token },
  });
  return addFaq;
};

export const FetchGeneralSetting = async (token) => {
  const setting = await axios.get(Services.fetchSettingUrl, {
    headers: { Authorization: token },
  });
  return setting;
};

export const InsertNewSetting = async (token, body) => {
  const addSetting = await axios.post(Services.AddSettingUrl, body, {
    headers: { Authorization: token },
  });
  return addSetting;
};
export const FetchOrderList = async (token, current, searchInput, status) => {
  const order = await axios.get(
    `${baseURL}/order-list?currentPage=${current}&itemsPerPage=${limit}&searchData=${searchInput}&statusFilter=${
      status != null ? status : ""
    }`,
    {
      headers: { Authorization: token },
    }
  );
  return order;
};

export const FetchOrderDetails = async (token, OrderId) => {
  const OrderDetails = await axios.get(`${baseURL}/order/${OrderId}`, {
    headers: { Authorization: token },
  });
  return OrderDetails;
};

export const FetchUserList = async (token, current, searchInput) => {
  const user = await axios.get(
    `${baseURL}/user-list?currentPage=${current}&itemsPerPage=${limit}&searchData=${searchInput}`,
    {
      headers: { Authorization: token },
    }
  );
  return user;
};

export const FetchContactDetails = async (token) => {
  const contact = await axios.get(Services.getContactUrl, {
    headers: { Authorization: token },
  });
  return contact;
};

export const InsertNewContactData = async (token, body) => {
  const contact = await axios.post(Services.addContactUrl, body, {
    headers: { Authorization: token },
  });
  return contact;
};

export const FetchVendorWallet = async (token, current, vendorId) => {
  const wallet = await axios.get(
    `${baseURL}/vendor/${vendorId}/wallet-recharge-history?currentPage=${current}&itemsPerPage=${limit}`,
    {
      headers: { Authorization: token },
    }
  );
  return wallet;
};

export const FetchVendorWalletDedect = async (token, current, vendorId) => {
  const wallet = await axios.get(
    `${baseURL}/vendor/${vendorId}/wallet-deduction-history?currentPage=${current}&itemsPerPage=${limit}`,
    {
      headers: { Authorization: token },
    }
  );
  return wallet;
};

export const ResendOrderToVendor = async (token, body) => {
  const reorder = await axios.post(Services.resendOrderUrl, body, {
    headers: { Authorization: token },
  });
  return reorder;
};

export const UploadPincodeExcel = async (token, body) => {
  const pincode = await axios.post(Services.pincodeExcelUrl, body, {
    headers: { Authorization: token },
  });
  return pincode;
};

export const FetchPincodeBaseOrder = async (token) => {
  const pincodeOrder = await axios.get(Services.PincodeTotalOrderUrl, {
    headers: { Authorization: token },
  });
  return pincodeOrder;
};
export const FetchStatusBaseOrder = async (token) => {
  const statusOrder = await axios.get(Services.TotalOrderStatusUrl, {
    headers: { Authorization: token },
  });
  return statusOrder;
};
export const fetchAllCountAnalitics = async (token) => {
  const statusOrder = await axios.get(Services.AllCountAnaliticsUrl, {
    headers: { Authorization: token },
  });
  return statusOrder;
};

export const fetchAfdminProfile = async (token) => {
  const profile = await axios.get(Services.fetchAdminProfileUrl, {
    headers: { Authorization: token },
  });
  return profile;
};

export const EditAdminProfile = async (body, token) => {
  const profile = await axios.put(Services.updateAdminProfileUrl, body, {
    headers: { Authorization: token },
  });
  return profile;
};

export const deleteVendor = async (body, token) => {
  const del = await axios.delete(Services.deleteVendorUrl, {
    data: body,
    headers: { Authorization: token },
  });
  return del;
};

export const fetchYearRevenue = async (token, year) => {
  const revenue = await axios.get(
    `${baseURL}/year-based-quarter-data?year=${year}`,
    {
      headers: { Authorization: token },
    }
  );
  return revenue;
};
