import React, { useEffect, useState } from "react";
import { Badge, Breadcrumb, Button, Card, Image, Pagination, Popconfirm, Statistic, Typography, message } from "antd";
import '../../style/location.css'
import { useLocation } from "react-router-dom";
import { Space, Table, Tag } from 'antd';
import AddOffers from "./AddOffers";
import { MdOutlineDelete } from "react-icons/md";
import { HiOutlineEye } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { FetchDiscountList, deleteDiscount } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import EditDiscount from "./EditDiscount";


const { Title } = Typography;
const options = {
    timeZone: 'UTC', // Set the time zone to UTC to prevent conversion
    year: 'numeric',
    // month: '2-digit',
    // day: '2-digit'
};








function DiscountList() {
    const location = useLocation();
    const { token, logout } = useAuth()
    const [discountData, setDiscountData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [current, setCurrent] = useState(1)
    const [totalPage, setTotalPage] = useState(null)

    const onChange = (page) => {
        setCurrent(page);
        setIsLoading(true);
    };


    const getNewDate = (expiryDate) => {
        const expiryDates = new Date(expiryDate);
        const options = {
            timeZone: 'UTC', // Set the time zone to UTC to prevent conversion
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };

        const formattedDate = expiryDates.toLocaleDateString('en-IN', options);

        return formattedDate
    }

    const cancel = () => {
        message.error('You are select No')
    }
    const columns = [
        {
            title:
                "     Discount Code"
            ,
            dataIndex: "code",
            key: "code",
            render: (_, { code }) => (
                <div className="amc_dis_code">
                    <Button>{code}</Button>
                </div>
            ),
        },
        {
            title:
                "  Discount Type"
            ,
            dataIndex: "discountType",
            key: "discountType",
        },
        {
            title:
                "Discount Value"
            ,
            dataIndex: "discountValue",
            key: "discountValue",
            render: (_, { discountType, discountValue }) => (
                <>
                    {discountType == "PERCENTAGE"
                        ? `${discountValue} %`
                        : `₹ ${discountValue} `}{" "}
                </>
            ),
        },

        {
            title:
                "  Start Date"
            ,
            dataIndex: "startDate",
            key: "startDate",
            render: (_, { startDate }) => (
                <Badge status="processing" text={new Date(startDate).toLocaleDateString()} />

            ),
        },
        {
            title:
                " Expire Date"
            ,
            dataIndex: "expiryDate",
            key: "expiryDate",
            render: (_, { expiryDate }) => (
                <Badge status="error" text={getNewDate(expiryDate)} />

            ),
        },
        {
            title: " Status"
            ,
            dataIndex: "status",
            key: "status",
            render: (_, { status }) => (
                <Tag color={status != "ACTIVE" ? "#E72929" : "#A1DD70"}>{status}</Tag>

            ),
        },
        {
            title:
                " Action"
            ,
            dataIndex: "_id",
            key: "_id",
            render: (_, { _id }) => (
                <div className="vendor_action">
                    {/* <EditDiscount /> */}
                    <Popconfirm
                        title="Delete the Discount"
                        description="Are you sure to delete this Discount?"
                        onConfirm={() => deleteDiscountHandle(_id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <MdOutlineDelete className="delete_icon" />
                    </Popconfirm>



                </div>

            ),
        },
    ];

    const ShowAllDiscountList = async () => {
        try {
            await FetchDiscountList(token, current)
                .then((res) => {
                    console.log(" banner  list ", res);
                    if (res.status == 200) {
                        setDiscountData(res.data.data.data);
                        setTotalPage(res.data.data.totalPage);
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowAllDiscountList()
    }, [current])

    const deleteDiscountHandle = async (id) => {
        try {
            let body = {
                discountId: id,
            };
            await deleteDiscount(body, token)
                .then((res) => {
                    console.log("delete discount ", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        ShowAllDiscountList();
                    }
                })
                .catch((err) => {
                    console.log(err.response.msg);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true);
        }
    };



    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_title">
                        <Title level={4}>Discount List</Title>
                    </div>
                    <div className="content_add">
                        <AddOffers ShowAllDiscountList={ShowAllDiscountList} />
                    </div>
                </div>
                <div className="content">
                    <div className="shoo_recent_order">
                        {!isLoading ? <div className="loader_main"> <span class="loader"></span></div> : <Table columns={columns} dataSource={discountData} scroll={{ x: true }}
                            pagination={false}
                            footer={() => <div className="pagination">
                                <Pagination current={current} onChange={onChange} total={totalPage * 10} />
                            </div>}
                        />}
                    </div>
                </div>


            </div>

        </section>
    );
}









export default DiscountList