'use strict';

import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { AgCharts } from 'ag-charts-react';
import { Card, DatePicker, message } from 'antd';
import { fetchYearRevenue } from '../../../service/api_services';
import { useAuth } from '../../../authentication/context/authContext';
// import { fetchDashboardUserData } from '../../service/api_services';
// import { useAuth } from '../../authentication/context/authContext';

const TotalOrderPichart = () => {
    const { logout, token } = useAuth()
    const [years, setYears] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [revenueData, setRevenueData] = useState([])
    const [options, setOptions] = useState({
        title: {
            text: "Annual Revenue ",
        },
        subtitle: {
            text: "Plateform Revenue, GST Revenue , Vendor Wallet Deduction Revenue",
        },
        data: [],
        series: [
            {
                type: "line",
                xKey: "quarter",
                yKey: "plateformFee",
                yName: "Plateform Amount",
            },
            {
                type: "line",
                xKey: "quarter",
                yKey: "VendorDeduction",
                yName: "Vendor Amount Deduction",
            },
            {
                type: "line",
                xKey: "quarter",
                yKey: "gst",
                yName: "GST Amount",
            },
        ],
    });
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    const onChange = (date, dateString) => {
        setYears(dateString)
        console.log("yeaer", dateString)
    };

    const showYearRevenueData = async () => {
        try {
            const res = await fetchYearRevenue(token, years != null ? years : currentYear);
            console.log("revenue year", res);
            if (res.status === 200) {
                const data = res.data.data;
                setRevenueData(data);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    data: data.map(item => ({
                        quarter: `Q${item.quarter}`,
                        VendorDeduction: item.totalVendorWalletDeduction,
                        plateformFee: item.totalPlatformAmount,
                        gst: item.totalGstAmount,
                    })),
                }));
                setIsLoading(true);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        showYearRevenueData();
    }, [years]);

    useEffect(() => {
        if (isLoading) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                data: revenueData.map(item => ({
                    quarter: `Q${item.quarter}`,
                    VendorDeduction: item.totalVendorWalletDeduction,
                    plateformFee: item.totalPlatformAmount,
                    gst: item.totalGstAmount,
                })),
            }));
        }
    }, [revenueData, isLoading]);


    return (
        <Card title={<DatePicker
            onChange={onChange}
            // defaultValue={defaultMomentValue}
            picker="year" />}>
            <AgCharts options={options} />
        </Card>

    )
};

export default TotalOrderPichart


