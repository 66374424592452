import { Icons } from "../../common/icons";
import OrderList from "../OrderManage/OrderList";
import { v4 as uuidv4 } from 'uuid';
import Cateogry from "../master/category/Cateogry";

import DeliveryBoy from "../vendor/DeliveryPartner";
import ServcieList from "../service/ServcieList";
import BannerManage from "../banner/BannerManage";
import DiscountList from "../offerManane/DiscountList";
import PincodeList from "../master/pincode/PincodeList";
import RoleManagement from "../../authentication/role/RoleManagement";
import UserList from "../user/UserList";
import FaqList from "../cms/FAQ/FaqList";
import Dashboard from "../dashboard/dashboard-analitics/Dashboard";


// Main routes object with sub-menu items
const routes = [
  {
    key: uuidv4(),
    path: "/dashboard",
    icons: Icons.Admin,
    label: "Dashboard",
    component: Dashboard,
  },
  {
    key: uuidv4(),
    path: "/dashboard/order",
    icons: Icons.order,
    label: "Order List",
    component: OrderList,
  },
  {
    key: uuidv4(),
    path: "/dashboard/user",
    icons: Icons.User,
    label: "User List",
    component: UserList,
  },

  {
    key: uuidv4(),

    path: "/dashboard/vendor-list",
    icons: Icons.User,
    label: "Vendor ",
    component: DeliveryBoy,
  },
  {
    key: uuidv4(),

    path: "/dashboard/service",
    icons: Icons.Service,
    label: "Service ",
    component: ServcieList,
  },
  {
    key: uuidv4(),

    path: "/dashboard/banner",
    icons: Icons.banner,
    label: "Banner Manage ",
    component: BannerManage,
  },




  {
    key: uuidv4(),

    path: "/dashboard/category",
    icons: Icons.Category,
    label: "Category",
    component: Cateogry,
  },

  {
    key: uuidv4(),

    path: "/dashboard/discount",
    icons: Icons.discount,
    label: "Discount",
    component: DiscountList,
  },

  {
    key: uuidv4(),

    path: "/dashboard/pincode",
    icons: Icons.pin,
    label: "Pincode",
    component: PincodeList,
  },
  // {
  //   key: uuidv4(),

  //   path: "/dashboard/role",
  //   icons: Icons.User,
  //   label: "Role Mange",
  //   component: RoleManagement,
  // },
  {
    key: uuidv4(),

    path: "/dashboard/faq",
    icons: Icons.faq,
    label: "FAQ",
    component: FaqList,
  },


];


export default routes;
