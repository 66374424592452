import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Breadcrumb, Button, Card, Checkbox, Descriptions, Form, Image, Input, List, Pagination, Popconfirm, Select, Statistic, Typography, message } from "antd";
import '../../style/product.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Space, Table, Tag } from 'antd';
import { HiOutlineEye } from "react-icons/hi";
import { MdOutlineDelete } from "react-icons/md";
import { ApprovalStatus, deleteVendor, documentStatus, FetchVendorList } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";

import { AiOutlineUser } from "react-icons/ai";
import { SearchOutlined } from '@ant-design/icons';
import _debounce from 'lodash/debounce';

const { Title } = Typography;







function DeliveryPartner() {
  const navigate = useNavigate()
  const location = useLocation();
  const { token, logout } = useAuth()
  const [vendorList, setVendorList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isDocUpload, setIsDocUpload] = useState("")
  const [isDocApprove, setIsDocApprove] = useState("")
  const [totalPage, setTotalPage] = useState(null)
  const [current, setCurrent] = useState(1)
  const [searchInput, setSearchInput] = useState("")


  const columns = [
    {
      title: 'Image',
      dataIndex: 'profilePic',
      key: 'profilePic',
      render: (_, { profilePic }) => (
        <div className="show_cat_img" >
          {profilePic != null ?
            <Image src={profilePic} width={40} height={40} style={{ objectFit: "cover", borderRadius: "100%" }} /> : <Avatar
              size={40}
              style={{
                backgroundColor: '#f8229f',
              }}
              icon={<AiOutlineUser className="user_icon" />}
            />}

        </div>
      )
    },
    {
      title:
        " Name"
      ,
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title:
        "Number"
      ,
      dataIndex: "mobileNo",
      key: "nummobileNober",
    },
    {
      title:
        "Email"
      ,
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => (
        <div>
          {email != null ? <span>{email}</span> : <span style={{ color: "gray" }}>N/A</span>}
        </div>
      )
    },
    {
      title:
        "Document"
      ,
      dataIndex: "isDoucmentUploaded",
      key: "isDoucmentUploaded",
      render: (_, { isDoucmentUploaded }) => (
        <div>
          {isDoucmentUploaded == true ? <Tag color="green">Uploaded</Tag> : <Tag color="red">Not Upload</Tag>}
        </div>
      )
    },
    {
      title:
        "Wallet"
      ,
      dataIndex: "walletAmount",
      key: "walletAmount",
      render: (_, { walletAmount, _id }) => (

        <Link id="wallet_amt" to={`/dashboard/vendor-wallet/${_id}`} ><strong>₹ {walletAmount}</strong></Link>
      )
    },
    {
      title:
        "Status"
      ,
      dataIndex: "isDocumentVerified",
      key: "isDocumentVerified",
      render: (_, { isDocumentVerified }) => (
        <div>
          {isDocumentVerified == false ? <Tag color="crimson">Rejecet</Tag> : isDocumentVerified == true ? <Tag color="#90D26D">Approved</Tag> : <Tag color="#B5C0D0">Pending</Tag>}
        </div>
      )
    },
    {
      title:
        "Action"
      ,
      dataIndex: "_id",
      key: "_id",
      render: (_, { _id }) => (
        <div className="vendor_action">
          <Space>
            <HiOutlineEye className="view_details" onClick={() => navigate(`/dashboard/vendor-details/${_id}`)} />
            <Popconfirm
              title="Delete the Vendor"
              description="Are you sure to delete Venodr?"
              onConfirm={() => DeleteVendor(_id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >

              <MdOutlineDelete className="delete_icon" />
            </Popconfirm>
          </Space>

        </div>
      )
    },
  ];

  const cancel = () => {
    message.error("you are select no")
  }
  const onChange = (page) => {
    setCurrent(page);
    setIsLoading(true);
  };


  const ShowAllVendorList = async () => {
    try {
      await FetchVendorList(
        token,
        current,
        isDocApprove,
        isDocUpload,
        searchInput)
        .then((res) => {
          console.log(" all vendor list ", res);
          if (res.status == 200) {
            setVendorList(res.data.data.data);
            setTotalPage(res.data.data.totalPage)
            setIsLoading(true)
          } else if (res.data.code == 283) {
            message.error(res.data.message)
            logout()
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(true)
    }
  };

  useEffect(() => {
    ShowAllVendorList()
  }, [current,isDocUpload,isDocApprove,searchInput])

  const DeleteVendor = async (id) => {
    try {

      await deleteVendor({ vendorId: id }, token)
        .then((res) => {
          console.log("delete fvednoraq ", res);
          if (res.status == 201) {
            message.success(res.data.message);
            ShowAllVendorList();
          }
        })
        .catch((err) => {
          console.log(err.response.msg);
        });
    } catch (error) {
      console.log(error);
    }
  };


  const handleSearchChange = useCallback(_debounce((value) => {
    setSearchInput(value);
  }, 1000), []);


  return (
    <section className="main_Section">
      <Breadcrumb
        items={[
          {
            title: "Dashboard",
          },
          {
            title: location.pathname,
          },
        ]}
      />
      <div className="content_title">
        <div className="content_head">
          <div className="content_titles">
            <div className="hear_title">
              <Title level={4}>Vendor List </Title>
            </div>

          </div>
          <div className="content_add">
            <div className="search_filter">
              <Space>

                <Form.Item name="isDocumentUpload">
                  <Select
                    allowClear
                    placeholder="Select Document Action"
                    optionFilterProp="children"
                    onChange={(value) => setIsDocUpload(value)}
                    style={{ width: '200px' }}
                  >
                    {documentStatus.map((opt) => (
                      <Select.Option key={opt.key} value={opt.value}>{opt.label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="payment_mode">
                  <Select
                    allowClear
                    placeholder="Select Document Approval"
                    optionFilterProp="children"
                    onChange={(value) => setIsDocApprove(value)}
                    style={{ width: '200px' }}
                  >
                    {ApprovalStatus.map((opt) => (
                      <Select.Option key={opt.key} value={opt.value}>{opt.label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="Search Name & Number">
                  <Input
                    style={{ width: '200px' }}
                    allowClear
                    placeholder="Search Name or  No."
                    suffix={<SearchOutlined />}
                    onChange={(e) => handleSearchChange(e.target.value)}
                  />
                </Form.Item>

              </Space>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="shoo_recent_order">
            {!isLoading ? <div className="loader_main"> <span className="loader"></span></div> : <Table style={{ cursor: "pointer" }} columns={columns} dataSource={vendorList} scroll={{ x: true }}
              pagination={false}
              footer={() => <div className="pagination">
                <Pagination current={current} onChange={onChange} total={totalPage * 10} />
              </div>}

            />}
          </div>
        </div>


      </div>

    </section>
  );
}




export default DeliveryPartner