import { Drawer, Input } from 'antd';

import React, { useEffect, useState } from 'react';
import { Button, Form, message, Modal, Space, Upload } from 'antd';
import {

    EditOutlined,
} from "@ant-design/icons";
import { useAuth } from '../../authentication/context/authContext';
import { Col, Row } from 'react-bootstrap';
import defaultImg from "../../assest/icon/imageIcon.png"
import { EditAdminProfile } from '../../service/api_services';
import '../../style/vendor.css'

const UpdateProfile = ({ editData }) => {
    const [open, setOpen] = useState(false);
    const { token } = useAuth()
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(new FormData());
    const [uploder, setUploader] = useState(null)

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (value) => {
        console.log("ddfdfsdfffdf", value)
        try {
            uploder != null &&
                formData.append("profilePic", value.profilePic.file);
            formData.append("fullName", value.fullName);

            await EditAdminProfile(formData, token)
                .then((res) => {
                    console.log(" update image", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        setOpen(false);
                        setFormData(new FormData());
                        onReset()
                    } else if (res.status == 200) {
                        message.error(res.data.message);
                        setFormData(new FormData());

                    }
                })
                .catch((err) => {
                    message.error(err.message);
                    setFormData(new FormData());

                });
        } catch (error) {
            console.log(error);
            setFormData(new FormData());

        }
    };

    const handlerImage = (file) => {
        console.log(file)
        setUploader(file.fileList)
    }

    const onFinishFailed = (value) => {
        console.log(value)
    }
    const onReset = () => {
        form.resetFields()
    }
    const handleCancel = () => {
        setOpen(false)
        onreset()
    }

    useEffect(() => {
        form.setFieldsValue({

            fullName: editData?.fullName,
            email: editData?.email,
        })
    }, [])

    return (
        <>
            <EditOutlined className='h5' onClick={showDrawer} />

            <Drawer title="Update Profile" onClose={onClose} open={open}>
                <div className="update_profile_img">
                    <Form
                        form={form}
                        layout="vertical"
                        name="add-image"
                        className="images"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row>
                            <Col md={12}>
                                <Form.Item
                                    label="Name"
                                    name="fullName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter fullName",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter fullName" />
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                   
                                >
                                    <Input placeholder="Enter Email" disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="upload_form">
                            <Form.Item name="profilePic" label={uploder?.length != 1 ? "Choose  Image" : "If you want to choose other image, remove exit Image"}>
                                <Upload
                                    multiple={false}
                                    listType="picture-circle"
                                    beforeUpload={() => false} // To prevent automatic upload
                                    accept="jpg/jpeg/pdf"
                                    onChange={handlerImage}
                                >
                                    {uploder?.length != 1 ? <img src={editData?.profilePic} className="image_icons" /> : null}
                                </Upload>
                            </Form.Item>

                            <div className="model_Subvbmit">
                                <Space>
                                    <Button onClick={handleCancel} shape="round" danger>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="primary_btn"
                                        shape="round"
                                    >
                                        Submit
                                    </Button>
                                </Space>
                            </div>
                        </div>
                    </Form>
                </div>
            </Drawer>
        </>
    );
};

export default UpdateProfile