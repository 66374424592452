import React, { useState } from "react";
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Space,
    Upload,
    message,
} from "antd";
import "../../../style/vendor.css"
import { InsertNewCategory, InsertNewFAQ } from "../../../service/api_services";
import { useAuth } from "../../../authentication/context/authContext";



function AddFaq({ ShowAllFAQList }) {
    const { token } = useAuth()
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(new FormData());
    const [form] = Form.useForm();
    const [uploder, setUploader] = useState(null)




    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onFinish = async (value) => {
        try {
            await InsertNewFAQ(token, value)
                .then((res) => {
                    console.log(" add new faq", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        setOpen(false);
                        onReset();
                        ShowAllFAQList()
                    } else if (res.status == 200) {
                        message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    message.error(err.message);


                });
        } catch (error) {
            console.log(error);


        }
    };

    const onReset = () => {
        form.resetFields();
    };



    return (
        <div className="modal_section">

            <Button
                type="primary"
                className="primary_btn"
                shape="round"
                onClick={showDrawer}
            >
                Add
            </Button>

            <Drawer
                title="Add New FAQ"
                placement="right"
                width={400}

                onClose={onClose}
                open={open}
            >
                <div className="add_category_form">
                    <Form
                        form={form}
                        layout="vertical"
                        name="add-image"
                        className="images"
                        initialValues={{}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Col md={24}>
                            <Form.Item
                                label="Enter Question"
                                name="question"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Question",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Question" />
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                label="Enter Answer"
                                name="answer"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Answer",
                                    },
                                ]}
                            >
                                <Input.TextArea  placeholder="Enter Answer" />
                            </Form.Item>
                        </Col>
                        <div className="model_Submit">
                            <Button onClick={onClose} shape="round" danger>
                                Cancel
                            </Button>
                            <Button
                                htmlType="submit"
                                shape="round"
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </Drawer>
        </div>
    );
}

export default AddFaq;