import { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [fcmToken, setFcmToken] = useState("");
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("currentUser")) || null
  );

  const setNewFcmToken=(newFcm)=>{
    setFcmToken(newFcm)
  }


  const login = (newToken) => {
    setToken(newToken);
  };

  const logout = () => {
    setToken(null);
    localStorage.clear("token", null);
  };
  const currentUserData = (user) => {
    setCurrentUser(user);
  };
  return (
    <AuthContext.Provider
      value={{ token, login, logout, fcmToken, setNewFcmToken,currentUser,currentUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};
