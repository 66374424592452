import React, { useState } from "react";
import { Button, Modal } from "antd";
import { FaPlus } from "react-icons/fa";
import SignUp from "../signup/SignUp";

function CreateAdmin({Showservice}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="modalBtns">
      <Button  onClick={showModal}  shape="round">Add  </Button>
      <div className="create_post">
        <Modal
          title="Add New Admin"
          width={400}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          style={{
            top: 20,
          }}
        >
         <SignUp />
        </Modal>
      </div>
    </div>
  );
}




export default CreateAdmin