import { Avatar, Dropdown, Image, Menu, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Icons } from '../../common/icons';
import ChangePassword from '../../pages/ChangePassword';
import { FaRegEye } from 'react-icons/fa';

import { useAuth } from '../../authentication/context/authContext';
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineUser } from 'react-icons/ai';




function AdminMenu() {
    const { logout } = useAuth()

    const navigate = useNavigate()
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <p className='menuItem' onClick={() => navigate("/dashboard/profile")} style={{ marginBottom: 0, }}>
                            <FaRegEye /> View Profile
                        </p>
                    ),
                },
                // {
                //     key: '2',
                //     label: (
                //         <ChangePassword />
                //     ),
                // },
                {
                    key: '3',
                    label: (
                        <p className='menuItem' onClick={() => logout()} style={{ marginBottom: 0, }}>
                            <BiLogOutCircle /> Logout
                        </p>
                    ),
                },


            ]}
        />
    );




    return (

        <>
            <Space direction="vertical">
                <Space wrap>
                    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']} >
                        {

                            <Avatar
                                style={{
                                    backgroundColor: '#f8229f',
                                    cursor: "pointer"
                                }}
                                icon={<AiOutlineUser className="user_icon" />}
                            />
                        }


                    </Dropdown>

                </Space>
            </Space>
        </>
    )
}


export default AdminMenu;