'use strict';

import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { AgCharts } from 'ag-charts-react';
import { Card, DatePicker, message } from 'antd';

import { FetchPincodeBaseOrder } from '../../../service/api_services';
import { useAuth } from '../../../authentication/context/authContext';

const TotalPincodeOrder = () => {
    const { logout, token } = useAuth();
    const [bookingData, setBookingData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [options, setOptions] = useState({
        title: {
            text: "Top Service Pincode ",
        },
        subtitle: {
            text: ' Top 10 Service Pincode Booking',
        },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'quarter',
                yKey: "totalOrder",
                yName: 'pincode',
            },

        ],
        animation: {
            enabled: true,
            duration: 1000,
        },
    });

    const showServiceBookingChart = async () => {
        try {
            const res = await FetchPincodeBaseOrder(token);
            console.log("pincode base order", res);
            if (res.status === 200) {
                const data = res.data.data;
                setBookingData(data);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    data: data.map(item => ({
                        quarter: item.pincode,
                        pincode: item.pincode,
                        totalOrder: item.totalOrder,
                    })),
                }));
                setIsLoading(true);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        showServiceBookingChart();
    }, []);

    useEffect(() => {
        if (isLoading) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                data: bookingData.map(item => ({
                    quarter: item.pincode,
                    pincode: item.pincode,
                    totalOrder: item.totalOrder,
                })),
            }));
        }
    }, [bookingData, isLoading]);



    return (
        <div className="book_charts">
            <Card

            >
                <AgCharts options={options} />
            </Card>
        </div>
    );
};

export default TotalPincodeOrder;