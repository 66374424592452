// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyB9aumjyBX_So3HrNXY_lAWOpm29-mm_00",
    authDomain: "cutenails-7a31c.firebaseapp.com",
    projectId: "cutenails-7a31c",
    storageBucket: "cutenails-7a31c.appspot.com",
    messagingSenderId: "260750834148",
    appId: "1:260750834148:web:79d52f52ca810dfd086ff1",
    measurementId: "G-DL28G90ZN4"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export  {messaging,getToken,onMessage};
