import React, { useEffect, useState } from 'react';
import "./dashboard.css"
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Badge, Input, Layout, Menu, theme } from 'antd';
import SideBarList from '../sidebar/SideBarList';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import AdminMenu from '../navbar/AdminMenu';
import { useAuth } from '../../authentication/context/authContext';
import { MdOutlineSettings } from 'react-icons/md';
const { Header, Content, Footer, Sider } = Layout;
const items = [UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined].map(
  (icon, index) => ({
    key: String(index + 1),
    icon: React.createElement(icon),
    label: `nav ${index + 1}`,
  }),
);


const MainLayout = () => {
  const navigate = useNavigate()
  const { token, currentUser } = useAuth()
  const { Search } = Input;
  
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  useEffect(() => {
    changeNavbarColor()
  }, [window.scrollY])



  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  if (!token) {
    return <Navigate replace to="/" />;
  } else {

    return (
      <div className='main_layout'>
        <Layout >
          <SideBarList />
          <Layout>
            <Header
              className={
                colorChange
                && "colorChange"

              }
              style={{
                position: 'sticky',
                top: "1rem",
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                background: colorBgContainer,
                margin: "1rem",
                borderRadius: 10
              }}
            >
              <div className="head_menu">
                <div className="head_title">
                  <p>Welcome { currentUser?.fullName} !</p>
                </div>


                <div className="header_menu_icon">
                  <div className="hear_icons">

                    <MdOutlineSettings className="icon_navbar" onClick={() => navigate("/dashboard/setting")} />


                  </div>
                  <div className="header_menus">
                    <AdminMenu />
                  </div>
                </div>


              </div>
            </Header>
            <Content
              style={{
                margin: '0 16px',
                marginBottom: "1rem"
              }}
            >
              <div
                style={{
                  padding: 24,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                  minHeight: 500,

                }}
              >
                <Outlet />
              </div>
            </Content>

          </Layout>
        </Layout>
      </div>
    );
  }
};
export default MainLayout;