import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { Icons } from '../../common/icons';
import { FaEye } from "react-icons/fa";
import "../../style/service.css"


const ShowDescription = ({ descriptions }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className='show_des'>
            <Button type="link" onClick={showModal} icon={<FaEye className='des_eye' />}>

            </Button>
            <Modal footer={false} title="Description" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <ul>
                    {descriptions?.map((des) => (<li>{des}</li>))}
                </ul>
            </Modal>
        </div>
    );
};
export default ShowDescription;