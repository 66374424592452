import "./sidebar.css";
import React, { useRef, useState } from "react";
import "./sidebar.css";
import { NavLink, useNavigate } from "react-router-dom";




import Logo from "../../assest/chat/logo.png";
import routes from "./SideBarRoute";
import { Divider, Layout, Menu } from "antd";

const SideBarList = () => {
  const {  Sider } = Layout;
  
  const navigate = useNavigate();




  return (
    <div className="sidebarMenuItem">


      <Sider breakpoint="lg"
      id="sidebar"
      width={240} 
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        style={{
          position: 'sticky',
          left: 0,
          top: 0,
        }}

      >

        <div className="side_log">
          <img src={Logo} width={120} />

        </div>
        <Divider style={{margin:"0"}}/>
        <Menu theme="light" mode="inline"
          defaultSelectedKeys={['1']}
         >


          {routes.map((route, index) => {
            if (route.children) {
              return (

                <Menu.SubMenu
                  key={index}
                  title={<span className="side_sub_menu">{route.label}</span>}
                  icon={<span className="side_menu_icon">{route.icons}</span>}
                // onClick={() => handleSubMenuClick(route.key)}
                >
                  {route.children.map((subRoute, subIndex) => (
                    <Menu.Item
                      key={subIndex}
                      icon={<span className="side_menu_icon">{subRoute.icons}</span>}

                    >
                      <NavLink to={subRoute.path} >{subRoute.label}</NavLink>

                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <>

                <Menu.Item
                  key={index}
                  icon={<span className="side_menu_icon">{route.icons}</span>}
                  className="ripple-button"
                >
                  <NavLink to={route.path} >{route.label}</NavLink>

                </Menu.Item>
              </>
            );
          })}
        </Menu>
      </Sider>
      ;
    </div>
  );
};

export default SideBarList;
